import React, { Suspense, useLayoutEffect } from "react";
import { BrowserRouter as Router, Route, withRouter } from "react-router-dom";
import { LanguageContextProvider } from "./context/LanguageContext";

// Preloader
const Preloader = React.lazy(() => import("./components/layouts/Preloader"));

// Pages

const Homethree = React.lazy(() => import("./components/pages/Homethree"));

const Roomlist = React.lazy(() => import("./components/pages/Roomlist"));
const Roomdetails = React.lazy(() => import("./components/pages/Roomdetails"));

const Contact = React.lazy(() => import("./components/pages/Contact"));
const About = React.lazy(() => import("./components/pages/About"));
const Gallery = React.lazy(() => import("./components/pages/Gallery"));

const Rooms = React.lazy(() => import("./components/pages/Rooms"));

// Scroll to Top
const ScrollToTop = withRouter(({ children, location: { pathname } }) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children || null;
});

function App() {
  return (
    <LanguageContextProvider>
      <Router>
        <Suspense fallback={<div></div>}>
          <ScrollToTop>
            <Preloader />
            <Route path="/" exact component={Homethree} />
            <Route path="/rooms" exact component={Rooms} />
            <Route path="/room-list" exact component={Roomlist} />
            <Route path="/room-details/:id" exact component={Roomdetails} />
            <Route path="/contact" exact component={Contact} />
            <Route path="/about" exact component={About} />
            <Route path="/gallery" exact component={Gallery} />
          </ScrollToTop>
        </Suspense>
      </Router>
    </LanguageContextProvider>
  );
}

export default App;
