import React, { createContext, useState, useEffect } from "react";
import navigationITA from "../data/navigation.json";
import navigationENG from "../data/navigationENG.json";
import navigationFR from "../data/navigationFR.json";
import roomITA from "../data/room.json";
import roomENG from "../data/roomENG.json";
import roomFR from "../data/roomFR.json";
import homeITA from "../data/ITA/homeITA.json";
import sidebarITA from "../data/ITA/sidebarITA.json";
import sidebarENG from "../data/ENG/sidebarENG.json";
import sidebarFR from "../data/FR/sidebarFR.json";
import authorsITA from "../data/ITA/authorsITA.json";
import covidITA from "../data/ITA/covidITA.json";
import aboutITA from "../data/ITA/aboutITA.json";
import contactDetailsITA from "../data/ITA/contactDetailsITA.json";
import homeENG from "../data/ENG/homeENG.json";
import covidENG from "../data/ENG/covidENG.json";
import authorsENG from "../data/ENG/authorsENG.json";
import aboutENG from "../data/ENG/aboutENG.json";
import contactDetailsENG from "../data/ENG/contactDetailsENG.json";
import homeFR from "../data/FR/homeFR.json";
import covidFR from "../data/FR/covidFR.json";
import authorsFR from "../data/FR/authorsFR.json";
import aboutFR from "../data/FR/aboutFR.json";
import contactDetailsFR from "../data/FR/contactDetailsFR.json";

const LanguageContext = createContext();

const LanguageContextProvider = ({ children }) => {
  const [language, setLanguage] = useState("ITA");

  const toggleENG = () => setLanguage("ENG");
  const toggleITA = () => setLanguage("ITA");
  const toggleFR = () => setLanguage("FR");

  const getAllData = () => {
    if (language === "ITA") {
      return {
        navigation: navigationITA,
        homeData: homeITA,
        aboutData: aboutITA,
        contactData: contactDetailsITA,
        authors: authorsITA,
        covid: covidITA,
        rooms: roomITA,
        sidebar: sidebarITA,
      };
    }
    if (language === "ENG") {
      return {
        navigation: navigationENG,
        homeData: homeENG,
        aboutData: aboutENG,
        contactData: contactDetailsENG,
        authors: authorsENG,
        covid: covidENG,
        rooms: roomENG,
        sidebar: sidebarENG,
      };
    }
    if (language === "FR") {
      return {
        navigation: navigationFR,
        homeData: homeFR,
        aboutData: aboutFR,
        contactData: contactDetailsFR,
        authors: authorsFR,
        covid: covidFR,
        rooms: roomFR,
        sidebar: sidebarFR,
      };
    }
  };

  let pagelocation = [];
  if (language === "ITA") {
    navigationITA.map((nav) => {
      pagelocation.push(nav.linkText);
    });
  }
  if (language === "ENG") {
    navigationENG.map((nav) => {
      pagelocation.push(nav.linkText);
    });
  }
  if (language === "FR") {
    navigationFR.map((nav) => {
      pagelocation.push(nav.linkText);
    });
  }
  const contextValue = {
    pagelocation,
    language,
    getAllData,
    toggleENG,
    toggleITA,
    toggleFR,
  };
  return (
    <LanguageContext.Provider value={contextValue}>
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageContext, LanguageContextProvider };
